import * as R from 'ramda'
import { DEFAULT } from 'src/constants/order-origins'
import cartActions from '../../actions/cart'
import productActions from '../../actions/products'

export const defaultState = {
  data: {
    products: [],
    total_amount: 0,
    total_amount_without_offers: 0,
    offers: [],
  },
  catalog: null,
  loading: true,
  error: null,
  origin: DEFAULT,
  anticipation: {
    value: '1',
    description: '1 dia útil',
    liquidation_type: 'business-day',
  },
}

const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case productActions.types.FETCH_PRODUCTS: {
      if (state.catalog === action.payload.catalog) {
        return state
      }
      return {
        ...defaultState,
        catalog: action.payload.catalog,
      }
    }

    case cartActions.FETCH_REFERRER_METADATA: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }

    case cartActions.FETCH_REFERRER_METADATA_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    }

    case cartActions.ADD_COUPON: {
      return {
        ...state,
        data: {
          ...state.data,
          coupon: action.payload.coupon || undefined,
          referrer_name: action.payload.referrerName,
          referrer_id: action.payload.referrerId,
          referrer_custom_offer: action.payload.referrerCustom,
          subcoupon: action.payload.subcoupon,
          seller_id: action.payload.sellerId,
        },
        loading: true,
        error: null,
      }
    }

    case cartActions.ADD_COUPON_FAILED: {
      return {
        ...state,
        data: {
          ...state.data,
          coupon: undefined,
          referrer_custom_offer: undefined,
          subcoupon: undefined,
        },
        loading: false,
        error: action.payload,
      }
    }

    case cartActions.REMOVE_COUPON: {
      return {
        ...state,
        data: {
          ...state.data,
          coupon: undefined,
          referrer_name: undefined,
          referrer_id: undefined,
          referrer_custom_offer: undefined,
          subcoupon: undefined,
        },
        loading: true,
      }
    }

    case cartActions.CHANGE_PRODUCT_QUANTITY: {
      const { products } = state.data
      const { productId } = action.payload
      let { productQuantity } = action.payload

      const newProducts = products.map((product) => {
        if (product.id !== productId) {
          return product
        }

        if (product.max_quantity && productQuantity > product.max_quantity) {
          productQuantity = product.max_quantity
        }

        if (productQuantity < 1) {
          productQuantity = 1
        }

        return {
          ...product,
          quantity: productQuantity,
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
        },
        loading: true,
      }
    }

    case cartActions.INCREMENT_PRODUCT_QUANTITY: {
      const { products } = state.data
      const productId = action.payload

      const newProducts = products.map((product) => {
        if (product.id !== productId) {
          return product
        }

        if (product.max_quantity && product.max_quantity <= product.quantity) {
          return product
        }

        return {
          ...product,
          quantity: product.quantity + 1,
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
        },
        loading: true,
      }
    }

    case cartActions.DECREMENT_PRODUCT_QUANTITY: {
      const { products } = state.data
      const productId = action.payload

      const newProducts = products.map((product) => {
        if (product.id !== productId) {
          return product
        }

        const quantity = product.quantity <= 1 ? 1 : product.quantity - 1
        return {
          ...product,
          quantity,
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
        },
        loading: true,
      }
    }

    case cartActions.CHANGE_PRODUCT_CARRIER: {
      const { products } = state.data
      const { id, carrierName } = action.payload

      const newProducts = products.map((product) => {
        if (product.id !== id) {
          return product
        }

        const { modifiers } = product
        return {
          ...product,
          modifiers: {
            ...modifiers,
            simcards: [carrierName],
          },
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
        },
        loading: true,
      }
    }

    case cartActions.REMOVE_PRODUCT: {
      const { products } = state.data
      const productId = action.payload

      const newProducts = products.filter((product) => product.id !== productId)

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
        },
        loading: true,
      }
    }

    case cartActions.ADD_NEW_PRODUCT: {
      const { products } = state.data
      const { id, name, max_quantity: maxQuantity, modifiers, quantity } = action.payload

      const isAlreadyAdded = products.some((product) => product.id === id)

      let newProducts
      if (isAlreadyAdded) {
        newProducts = products.map((product) => {
          if (product.id !== id) {
            return product
          }

          if (maxQuantity && maxQuantity <= product.quantity) {
            return product
          }

          return {
            ...product,
            quantity: product.quantity + 1,
          }
        })
      } else {
        const newModifiers =
          modifiers && modifiers.simcards
            ? { simcards: [modifiers.simcards[0]] }
            : {}

        newProducts = [
          ...products,
          {
            ...action.payload,
            id,
            name,
            quantity,
            modifiers: newModifiers,
            amount: 0,
            total_amount: 0,
            total_amount_without_offers: 0,
          },
        ]
      }

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
        },
        loading: true,
      }
    }

    case cartActions.CREATE_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          coupon: R.pathOr(undefined, ['offers', 0, 'pk'], action.payload),
        },
      }
    }

    case cartActions.CREATE_CART_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case cartActions.GET_CART_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          coupon: R.pathOr(undefined, ['offers', 0, 'pk'], action.payload),
        },
      }
    }

    case cartActions.GET_CART_BY_ID_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case cartActions.UPDATE_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        error: null,
      }
    }

    case cartActions.UPDATE_CART_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case cartActions.SET_ORIGIN: {
      return {
        ...state,
        origin: action.payload,
      }
    }

    case cartActions.SET_ANTICIPATION: {
      return {
        ...state,
        anticipation: action.payload,
      }
    }

    default:
      return state
  }
}

export default cartReducer
