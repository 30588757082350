export const TONSUPER_REDIRECT_CATALOG_MAP = {
  ton: 'tonsuper',
  tonbase_tier: 'tonsuper',
}

export const MEGATON_REDIRECT_CATALOG_MAP = {
  novomegaton: 'tonmega',
  megaton: 'tonmega',
  megaton_out21: 'tonmega',
  megaton_tier: 'tonmega',
}

export const TONPRO_REDIRECT_CATALOG_MAP = {
  gigaton: 'tonpro_tier_nov24',
  gigaton_out21: 'tonpro_tier_nov24',
  gigaton_tier: 'tonpro_tier_nov24',
  ultraton: 'tonpro_tier_nov24',
  blackton: 'tonpro_tier_nov24',
  blackton_tier: 'tonpro_tier_nov24',
  tonbrother: 'tonpro_tier_nov24',
  tonbrother_tier: 'tonpro_tier_nov24',
  tonbrotherzero_tier: 'tonpro_tier_nov24',
  promoton_tier: 'tonpro_tier_nov24',
  tonpro_tier: 'tonpro_tier_nov24',
}

export const CATALOG_MAP = {
  ...TONSUPER_REDIRECT_CATALOG_MAP,
  ...TONPRO_REDIRECT_CATALOG_MAP,
  ...MEGATON_REDIRECT_CATALOG_MAP,
}

export const PRODUCT_MAP = {
  /* ton */
  TON_D150: 'TONSUPER_D150',
  TON_D195: 'TONSUPER_D195',
  TON_S920: 'TONSUPER_S920',
  TONBASE_SMART_POS: 'TONSUPER_SMART_POS',
  TON_T1_CHIP: 'TONSUPER_D195',

  /* tonbase_tier */
  TONBASE_TIER_D150: 'TONSUPER_D150',
  TONBASE_TIER_D195: 'TONSUPER_D195',
  TONBASE_TIER_S920: 'TONSUPER_S920',
  TONBASE_TIER_SMART_POS: 'TONSUPER_SMART_POS',
  TONBASE_TIER_T1_CHIP: 'TONSUPER_D195',

  /* tonsuper */
  TONSUPER_T1_CHIP: 'TONSUPER_D195',

  /* novomegaton */
  NOVOMEGATON_D150: 'TONMEGA_D150',
  NOVOMEGATON_D180: 'TONMEGA_D150',
  NOVOMEGATON_D195: 'TONMEGA_D195',
  NOVOMEGATON_S920: 'TONMEGA_S920',

  /* megaton */
  MEGATON_D150: 'TONMEGA_D150',
  MEGATON_D195: 'TONMEGA_D195',
  MEGATON_S920: 'TONMEGA_S920',
  MEGATON_SMART_POS: 'TONMEGA_SMART_POS',
  MEGATON_T1_CHIP: 'TONMEGA_D195',

  /* mega out21 */
  MEGATON_OUT21_D150: 'TONMEGA_D150',
  MEGATON_OUT21_D195: 'TONMEGA_D195',
  MEGATON_OUT21_S920: 'TONMEGA_S920',
  MEGATON_OUT21_T1_CHIP: 'TONMEGA_D195',

  /* megaton_tier_regular */
  MEGATON_TIER_D150: 'TONMEGA_D150',
  MEGATON_TIER_D195: 'TONMEGA_D195',
  MEGATON_TIER_S920: 'TONMEGA_S920',
  MEGATON_TIER_SMART_POS: 'TONMEGA_SMART_POS',
  MEGATON_TIER_T1_CHIP: 'TONMEGA_D195',

  /* gigaton */
  GIGATON_D150: 'TONPRO_TIER_NOV24_D150',
  GIGATON_D195: 'TONPRO_TIER_NOV24_D195',
  GIGATON_S920: 'TONPRO_TIER_NOV24_S920',
  GIGATON_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  GIGATON_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* giga out21 */
  GIGATON_OUT21_D150: 'TONPRO_TIER_NOV24_D150',
  GIGATON_OUT21_D195: 'TONPRO_TIER_NOV24_D195',
  GIGATON_OUT21_S920: 'TONPRO_TIER_NOV24_S920',
  GIGATON_OUT21_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* gigaton_tier  */
  GIGATON_TIER_D150: 'TONPRO_TIER_NOV24_D150',
  GIGATON_TIER_D195: 'TONPRO_TIER_NOV24_D195',
  GIGATON_TIER_S920: 'TONPRO_TIER_NOV24_S920',
  GIGATON_TIER_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  GIGATON_TIER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* ultraton */
  ULTRATON_D150: 'TONPRO_TIER_NOV24_D150',
  ULTRATON_D195: 'TONPRO_TIER_NOV24_D195',
  ULTRATON_S920: 'TONPRO_TIER_NOV24_S920',
  ULTRATON_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* blackton */
  BLACKTON_D150: 'TONPRO_TIER_NOV24_D150',
  BLACKTON_D195: 'TONPRO_TIER_NOV24_D195',
  BLACKTON_S920: 'TONPRO_TIER_NOV24_S920',
  BLACKTON_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  BLACKTON_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* blackton_tier */
  BLACKTON_TIER_D150: 'TONPRO_TIER_NOV24_D150',
  BLACKTON_TIER_D195: 'TONPRO_TIER_NOV24_D195',
  BLACKTON_TIER_S920: 'TONPRO_TIER_NOV24_S920',
  BLACKTON_TIER_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  BLACKTON_TIER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* tonbrother */
  TONBROTHER_D150: 'TONPRO_TIER_NOV24_D150',
  TONBROTHER_D195: 'TONPRO_TIER_NOV24_D195',
  TONBROTHER_S920: 'TONPRO_TIER_NOV24_S920',
  TONBROTHER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* tonbrother_tier */
  TONBROTHER_TIER_D150: 'TONPRO_TIER_NOV24_D150',
  TONBROTHER_TIER_D195: 'TONPRO_TIER_NOV24_D195',
  TONBROTHER_TIER_S920: 'TONPRO_TIER_NOV24_S920',
  TONBROTHER_TIER_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  TONBROTHER_TIER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* tonbrotherzero_tier */
  TONBROTHERZERO_TIER_D150: 'TONPRO_TIER_NOV24_D150',
  TONBROTHERZERO_TIER_D195: 'TONPRO_TIER_NOV24_D195',
  TONBROTHERZERO_TIER_S920: 'TONPRO_TIER_NOV24_S920',
  TONBROTHERZERO_TIER_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  TONBROTHERZERO_TIER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* promoton_tier */
  PROMOTON_TIER_D150: 'TONPRO_TIER_NOV24_D150',
  PROMOTON_TIER_D195: 'TONPRO_TIER_NOV24_D195',
  PROMOTON_TIER_S920: 'TONPRO_TIER_NOV24_S920',
  PROMOTON_TIER_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  PROMOTON_TIER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',

  /* tonpro_tier */
  TONPRO_TIER_D150: 'TONPRO_TIER_NOV24_D150',
  TONPRO_TIER_D195: 'TONPRO_TIER_NOV24_D195',
  TONPRO_TIER_S920: 'TONPRO_TIER_NOV24_S920',
  TONPRO_TIER_SMART_POS: 'TONPRO_TIER_NOV24_SMART_POS',
  TONPRO_TIER_T1_CHIP: 'TONPRO_TIER_NOV24_D195',
}
