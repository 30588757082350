import actionCreator from './actionCreator'

const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO'
const UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO'
const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO'
const UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO'

const UPDATE_BUSINESS_CNPJ_INFO = 'UPDATE_BUSINESS_CNPJ_INFO'
const UPDATE_PERSONAL_ADDRESS = 'UPDATE_PERSONAL_ADDRESS'
const UPDATE_BUSINESS_ADDRESS = 'UPDATE_BUSINESS_ADDRESS'

const updatePersonalInfo = actionCreator(UPDATE_PERSONAL_INFO)
const updateBusinessInfo = actionCreator(UPDATE_BUSINESS_INFO)
const updatePaymentInfo = actionCreator(UPDATE_PAYMENT_INFO)
const updateDeliveryInfo = actionCreator(UPDATE_DELIVERY_INFO)

// is new account flow
const updateBusinessCnpjInfo = actionCreator(UPDATE_BUSINESS_CNPJ_INFO)
const updatePersonalAddress = actionCreator(UPDATE_PERSONAL_ADDRESS)
const updateBusinessAddress = actionCreator(UPDATE_BUSINESS_ADDRESS)

export default {
  UPDATE_PERSONAL_INFO,
  UPDATE_BUSINESS_INFO,
  UPDATE_PAYMENT_INFO,
  UPDATE_DELIVERY_INFO,
  UPDATE_BUSINESS_ADDRESS,
  UPDATE_BUSINESS_CNPJ_INFO,
  UPDATE_PERSONAL_ADDRESS,
  updatePersonalInfo,
  updateBusinessInfo,
  updatePaymentInfo,
  updateDeliveryInfo,
  updateBusinessCnpjInfo,
  updatePersonalAddress,
  updateBusinessAddress
}
