import request from './createRequest/rendaextra'

const show = async ({ identifier, deviceToken }) => {
  const header = {
    headers: {
      'x-device-token': deviceToken,
    },
  }

  try {
    const { data } = await request.get(
      `/referrers/${identifier}/metadata/public`,
      header
    )

    return {
      sellerName: data.name,
      sellerCoupon: data.coupon,
      referrerId: data.referrer_id,
      referrerCustomOffer: data.custom_offer,
      sellerSubcoupon: data.subcoupon,
      sellerId: data.seller_id,
    }
  } catch (error) {
    if (error.statusCode === 409) {
      return Promise.reject(error)
    }

    return Promise.resolve({ sellerCoupon: identifier })
  }
}

export default {
  show,
}
