import stepFormActions from '../actions/stepForm'

const defaultState = {
  personalInfo: {
    data: {},
    errors: {},
  },
  businessInfo: {
    data: {},
    errors: {},
  },
  paymentInfo: {
    data: {},
    errors: {},
  },
  deliveryInfo: {
    data: {},
    errors: {},
  },
  personalAddress: {
    data: {},
    errors: {}
  },
  businessCnpjInfo: {
    data: {},
    errors: {}
  },
  businessAddress:{
    data: {},
    errors: {}
  }
}

const stepFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case stepFormActions.UPDATE_PERSONAL_INFO: {
      return {
        ...state,
        personalInfo: action.payload,
      }
    }
    case stepFormActions.UPDATE_BUSINESS_INFO: {
      return {
        ...state,
        businessInfo: action.payload,
      }
    }
    case stepFormActions.UPDATE_DELIVERY_INFO: {
      return {
        ...state,
        deliveryInfo: action.payload,
      }
    }
    case stepFormActions.UPDATE_PAYMENT_INFO: {
      return {
        ...state,
        paymentInfo: action.payload,
      }
    }
    case stepFormActions.UPDATE_BUSINESS_CNPJ_INFO: {
      return {
        ...state,
        businessCnpjInfo: action.payload,
      }
    }
    case stepFormActions.UPDATE_BUSINESS_ADDRESS: {
      return {
        ...state,
        businessAddress: action.payload,
      }
    }
    case stepFormActions.UPDATE_PERSONAL_ADDRESS: {
      return {
        ...state,
        personalAddress: action.payload,
      }
    }

    default:
      return state
  }
}

export default stepFormReducer
